import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { ChartjsTooltipComponent } from '@components/chartjs-tooltip/chartjs-tooltip.component';
import { ToastComponent } from '@components/toast/toast.component';
import { TooltipComponent } from '@components/tooltip/tooltip.component';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizationService } from '@services/localization/localization.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        CommonModule,
        RouterOutlet,
        ToastComponent,
        TranslateModule,
        TooltipComponent,
        ChartjsTooltipComponent,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    private localizationService: LocalizationService =
        inject(LocalizationService);
    private titleService: Title = inject(Title);

    constructor() {
        this.localizationService.init();
    }

    ngOnInit(): void {
        const currentDomain = window.location.hostname;

        if (currentDomain === 'platform.riforestry.com') {
            this.titleService.setTitle('RI Forestry');
        }
    }
}
