import { HttpRequest, HttpInterceptorFn } from '@angular/common/http';
import { AuthService } from './auth.service';
import { UserAuthenticated } from '@models/auth/auth.models';
import { inject } from '@angular/core';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
    const authService = inject(AuthService);

    const addToken = (req: HttpRequest<unknown>): HttpRequest<unknown> => {
        const user: UserAuthenticated | null = authService.getUser();
        let clone: HttpRequest<unknown> | undefined;

        if (user?.token) {
            clone = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${user.token}`,
                    TenantName: user.tenantName,
                },
            });
        }

        return clone ?? req;
    };

    const clone = addToken(req);
    return next(clone);
};
