import {
    ActiveElement,
    Tooltip,
    TooltipPositionerFunction,
    registerables,
} from 'chart.js';
import { Point } from 'chart.js';
import { Chart } from 'chart.js';

declare module 'chart.js' {
    interface TooltipPositionerMap {
        followCursor: TooltipPositionerFunction<'line'>;
    }
}

Tooltip.positioners.followCursor = (
    elements: readonly ActiveElement[],
    eventPosition: Point
) => {
    const { x, y } = eventPosition;
    return { x, y };
};

const chartConfig = (): void => {
    Chart.register(...registerables);
    Chart.defaults.font.family = 'Texta';
    Chart.defaults.font.size = 14;
    Chart.defaults.font.weight = 'bold';
    Chart.defaults.color = '#3E3E3E';
};

export default chartConfig;
