import { HttpInterceptorFn, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../pages/auth/services/auth.service';
// import { UserAuthenticated } from '@models/auth/auth.models';
import { catchError } from 'rxjs';
import { Router } from '@angular/router';
import { inject } from '@angular/core';

const HTTP_STATUS_401: number = 401;
// const HTTP_STATUS_403: number = 403;
// const HTTP_STATUS_404: number = 404;
// const HTTP_STATUS_500: number = 500;

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
    const authService = inject(AuthService);
    const router = inject(Router);

    return next(req).pipe(
        catchError((error: HttpErrorResponse) => {
            const ERROR_STATUS = error.status;

            switch (ERROR_STATUS) {
                case HTTP_STATUS_401:
                    if (!authService.getUser()) router.navigate(['login']);

                    break;
            }

            throw error;
        })
    );
};
