import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { Component, WritableSignal, inject } from '@angular/core';
import { BoxComponent } from '@components/box/box.component';
import { FlexComponent } from '@components/flex/flex.component';
import { IconComponent } from '@components/icon/icon.component';
import { ToastService } from '@services/toast/toast.service';

@Component({
    selector: 'app-toast',
    standalone: true,
    imports: [IconComponent, FlexComponent, BoxComponent],
    templateUrl: './toast.component.html',
    styleUrl: './toast.component.scss',
    animations: [
        trigger('slideUp', [
            state(
                'in',
                style({
                    opacity: 1,
                    transform: 'translateY(0px) translateX(-50%)',
                })
            ),
            transition('void => in', [
                animate(200),
                style({
                    opacity: 1,
                    transform: 'translateX(-50%) translateY(0px)',
                }),
            ]),
            transition('in => void', [
                animate(
                    200,
                    style({
                        opacity: 0,
                        transform: 'translateY(15px) translateX(-50%)',
                    })
                ),
            ]),
        ]),
    ],
})
export class ToastComponent {
    private toastService: ToastService = inject(ToastService);

    show: WritableSignal<boolean> = this.toastService.show;
    message: WritableSignal<string> = this.toastService.message;
    type: WritableSignal<string> = this.toastService.type;
}
