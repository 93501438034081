import { Routes } from '@angular/router';
import { AuthGuard } from './pages/auth/services/auth.guard';
import { RoleGuard } from './pages/auth/services/role.guard';

export const routes: Routes = [
    {
        path: 'login',
        loadChildren: () =>
            import('./pages/auth/auth.routes').then(r => r.AUTH_ROUTES),
    },
    {
        path: '',
        loadComponent: () =>
            import('./layout/layout.component').then(c => c.LayoutComponent),
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadComponent: () =>
                    import('./pages/dashboard/dashboard.component').then(
                        c => c.DashboardComponent
                    ),
            },
            {
                path: 'hazard-index',
                loadComponent: () =>
                    import(
                        './pages/flareless/hazard-zoning/hazard-zoning.component'
                    ).then(c => c.HazardZoningComponent),
            },
            {
                path: 'fuel-material',
                loadComponent: () =>
                    import(
                        './pages/flareless/fuel-material/fuel-material.component'
                    ).then(c => c.FuelMaterialComponent),
            },
            {
                path: 'water-stress',
                loadComponent: () =>
                    import(
                        './pages/flareless/water-stress/water-stress.component'
                    ).then(c => c.WaterStressComponent),
            },
            {
                path: 'static-zoning',
                loadComponent: () =>
                    import(
                        './pages/flareless/static-zoning/static-zoning.component'
                    ).then(c => c.StaticZoningComponent),
            },
            {
                path: 'vegetative-vigor',
                loadComponent: () =>
                    import(
                        './pages/flareless/vegetative-vigor/vegetative-vigor.component'
                    ).then(c => c.VegetativeVigorComponent),
            },
            {
                path: 'forest-supression',
                loadComponent: () =>
                    import(
                        './pages/forest/forest-supression/forest-supression.component'
                    ).then(c => c.ForestSupressionComponent),
            },
            {
                path: 'vegetative-performance',
                loadComponent: () =>
                    import(
                        './pages/ambiscience/vegetative-performance/vegetative-performance.component'
                    ).then(c => c.VegetativePerformanceComponent),
            },
            {
                path: 'deforestation',
                loadComponent: () =>
                    import(
                        './pages/ambiscience/deforestation/deforestation.component'
                    ).then(c => c.DeforestationComponent),
            },
            {
                path: 'settings',
                loadComponent: () =>
                    import('./pages/settings/settings.component').then(
                        c => c.SettingsComponent
                    ),
            },
            {
                path: 'users',
                loadComponent: () =>
                    import('./pages/users/users.component').then(
                        c => c.UsersComponent
                    ),
                canActivate: [RoleGuard],
                data: { permittedRoles: ['Admin', 'Owner'] },
            },
            {
                path: 'customers',
                loadComponent: () =>
                    import('./pages/clients/clients.component').then(
                        c => c.ClientsComponent
                    ),
                canActivate: [RoleGuard],
                data: { permittedRoles: ['Owner'] },
            },
        ],
    },
];
