import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {
    HttpClient,
    provideHttpClient,
    withInterceptors,
} from '@angular/common/http';
import { authInterceptor } from './pages/auth/services/auth.interceptor';
import { AuthGuard } from './pages/auth/services/auth.guard';
import { errorInterceptor } from './error/error.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import localePt from '@angular/common/locales/pt';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import chartConfig from './shared/chart-config/chart-config';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
    MomentDateAdapter,
    provideMomentDateAdapter,
} from '@angular/material-moment-adapter';
import 'moment/locale/pt-br';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { RoleGuard } from './pages/auth/services/role.guard';

chartConfig();

const LOCALES = [
    [localePt, 'pt'],
    [localeEs, 'es'],
];

for (const locale of LOCALES) {
    registerLocaleData(locale[0], locale[1]);
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        provideMomentDateAdapter({
            parse: {
                dateInput: ['l', 'LL'],
            },
            display: {
                dateInput: 'L',
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY',
            },
        }),
        provideRouter(routes),
        provideHttpClient(
            withInterceptors([authInterceptor, errorInterceptor])
        ),
        AuthGuard,
        RoleGuard,
        provideAnimations(),
        importProvidersFrom(
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: createTranslateLoader,
                    deps: [HttpClient],
                },
            })
        ),
    ],
};
