@if (show()) {
    @defer (on timer(100ms)) {
        <div class="toast-container" [@slideUp]="'in'">
            <app-flex align="center" [gap]="15">
                <div class="toast-container__icon-wrapper">
                    @switch (type()) {
                        @case ('success') {
                            <app-icon icon="success" color="#71C115" />
                        }
                        @case ('loading') {
                            <app-icon icon="spinner" />
                        }
                        @case ('alert') {
                            <app-icon icon="alert" color="#F63B23" />
                        }
                        @default {
                            <app-icon icon="success" color="#71C115" />
                        }
                    }
                </div>
                <span class="toast-container__message-wrapper">
                    {{ message() }}
                </span>
            </app-flex>
        </div>
    }
}
