import { Injectable, inject } from '@angular/core';
import { AuthService } from './auth.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable()
export class RoleGuard {
    private authService: AuthService = inject(AuthService);
    private router: Router = inject(Router);

    public canActivate(route: ActivatedRouteSnapshot): boolean {
        const permittedRoles: string[] = route.data['permittedRoles'] || [];
        const role = this.authService.getRole();

        if (!role || !permittedRoles.includes(role)) {
            this.router.navigate(['']);
            return false;
        }

        return true;
    }
}
